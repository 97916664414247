import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ForgotPwd.css';
import '../signIn/common-style.css';
import '../signIn/login.css';
import { isValidEmail } from '../../helper/utils/utility';
import apiUrls from '../../constants/apiUrls';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import { customFormDataPost2 } from '../../helper/api-helper/apiHerlpers';
import apiCommonParams from '../../constants/apiCommonParams';
import ReCAPTCHA from 'react-google-recaptcha';

const ForgotPwd = () => {
    const key = localStorage.getItem("keycloak1");
    const history = useHistory();
    const initialState = {
        email: '',
        captchaResponse: ''
    };
    const [formState, setFormState] = useState({ ...initialState });
    const [formErrors, setFormErrors] = useState({
        email: '',
        captchaResponse: ''
    });
    const { email } = formState;

    const onChangeReCaptcha = (value) => {
        setFormState({ ...formState, captchaResponse: value ? value : '' });
        let errors = { ...formErrors }
        if (value) {
            errors.captchaResponse = '';
        }
        setFormErrors({ ...formErrors, ...errors });
    }

    const onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });
        let errors = { ...formErrors }
        switch (name) {
            case 'email':
                errors.email = value.length < 1 ? 'This field is required!' : '';
                break;
            default:
                break;
        }
        setFormErrors({ ...formErrors, ...errors });
    }

    const isFormValid = () => {
        let valid = true;
        if (formState.email.length === 0 || formState.captchaResponse.length === 0) {
            let errors = { ...formErrors }
            if (formState.email.length === 0) {
                errors.email = 'This field is required!';
            }
            if (formState.captchaResponse.length === 0) {
                errors.captchaResponse = 'This field is required!';
            }
            setFormErrors({ ...formErrors, ...errors });
            return false;
        }
        return valid;
    }
    const submitMethod = (event) => {
        event.preventDefault();
        if (isFormValid()) {
            var body = new FormData();
            const data = {
                "state": null,
                "enabled": false,
                "source": null,
                "userVerifiationUUID": null,
                "paswordResetUUID": null,
                "profileImageType": null,
                "updatedTime": null,
                "address1": null,
                "isdCode1": null,
                "createdTime": null,
                "userModified": 0,
                "phone2": null,
                "address2": null,
                "userCreated": 0,
                "city": null,
                "zipCode": null,
                "profileImageUrl": null,
                "parentEmail": null,
                "isdCode2": null,
                "password": null,
                "username": formState.email,
                "userId": 0,
                "userStatus": null,
                "lastName": null,
                "applicationId": 252,
                "firstName": null,
                "email": null,
                "phone1": null,
                "userType": null,
                "passwordExpiryTime": null
            }
            body.append("userData", JSON.stringify(data));
            body.append("siteId", apiCommonParams.siteid);
            body.append("captchaResponse", formState.captchaResponse);
            body.append("swcmTicket", apiCommonParams.swcmTicket);

            trackPromise(
                customFormDataPost2(apiUrls.forgotPswd, body,key).then((res) => {
                    if (res === 'success') {
                        toast.success("Email sent successfully, please check your inbox", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setTimeout(() => {
                            history.push("/");
                        }, 500);
                    } else {
                        toast.warning(res, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                }).catch((error) => {
                    toast.error("Error", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            );
        }
    }

    const signinMethod = (event) => {
        event.preventDefault();
        history.push("/");
    }

    const signupMethod = (event) => {
        event.preventDefault();
        history.push("/signup");
    }

    const forgotUsernameMethod = (event) => {
        event.preventDefault();
        history.push("/forgot-username");
    }

    return (

        <div className="login-back login-top">
            <div className="row">
                <div className="container">
                    <div className="col-lg-10 col-md-12 col-md-offset-1 row-eq-height">
                        <div className="col-md-5 col-sm-12 col-xs-12 login-inner login-screen">
                            <h2>Hello...</h2>
                            <p className="text-left login-P-text">Enter your registered email address to reset your password</p>
                            <hr />
                            <h4>Don't have an account?</h4>
                            <span className="account-button" onClick={signupMethod}>
                                <i className="fa fa-user" aria-hidden="true"></i> Sign Up
                            </span>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12 account-create login-screen">
                            <h2>Forgot Password</h2>
                            <form name="resetPassword" id="resetPassword" method="post">
                                <div className="form-group mar-top-30">
                                    <input type="email" name="email" value={email} className="form-control" id="email" onChange={onChange} placeholder="Username or Email" />
                                    {formErrors.email.length > 0 && <small className="text-danger">{formErrors.email}</small>}
                                </div>
                                <div className="form-group mar-top-10 mt-4">
                                    <ReCAPTCHA sitekey="6LdoBoUeAAAAAC7ZQNII5NXJpTbg41dktbNDp6Wo" onChange={onChangeReCaptcha} />
                                    {formErrors.captchaResponse.length > 0 && <small className="text-danger">{formErrors.captchaResponse}</small>}
                                    <br />
                                </div>
                                <div className="form-group  mar-top-10 ">
                                    <button type="submit" onClick={submitMethod} className="btn btn-ar btn-primary mar-bot-30">Reset Password</button>
                                </div>
                                <div className="col-md-6 nopad smark-user">
                                    <p className="text-left"><span className="forgot" onClick={signinMethod}> <i className="fa fa-user" aria-hidden="true"></i> Sign In</span></p>
                                </div>
                                {/* <div className="col-md-6 nopad smark-user">
                                    <p className="text-left"><span className="forgot" onClick={forgotUsernameMethod} > <i className="fa fa-user" aria-hidden="true"></i> Forgot Username</span></p>
                                </div> */}
                                <div className="col-md-12">
                                    <img src={process.env.PUBLIC_URL + 'images/smark.png'} alt="Logo" width="150" className="img-responsive pull-right" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPwd;