import React, { useState,useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Signin.css';
import './common-style.css';
import './login.css';
//import { isValidEmail, passwordExpression } from '../../helper/utils';
import { toast } from 'react-toastify';
import { isAdminUser, loginUser } from '../../helper/context/actions';
import { useSmarkDispatch, useSmarkState } from '../../Context/KeycloakContext';
import { trackPromise } from 'react-promise-tracker';
import apiCommonParams from '../../constants/apiCommonParams';
import { useKeycloakState } from '../../Context/KeycloakContext'
import Loader from "react-loader-spinner";

const SignIn = () => {
    const dispatch = useSmarkDispatch();
    const { loading } = useSmarkState();
    const { login } = useKeycloakState();
    const keycloackValue = useKeycloakState();
    var axios = require("axios").default;
    const [key, setKey] = useState(" ");

    useEffect(() => {
        
       
        const qs = require('qs');
        let data = qs.stringify({
          'client_id': 'smark',
          'username': 'anju.tom',
          'password': 'anju@123',
          'grant_type': 'password',
          'client_secret': 'tnBpi5ptb3yhtlI99QWGGMMWf9PGH3lN' 
        });
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://smartwcm-keycloak-smartwcm-qa2.dark.swcm.link/realms/smark/protocol/openid-connect/token',
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
           
          },
          data : data
        };
        
        axios.request(config)
        .then(function (response) {
          setKey(response.data.access_token);
          
         // console.log(JSON.stringify(response.data));
          localStorage.setItem('keycloak1',response.data.access_token)
          
        })
        .catch((error) => {
          console.log(error);
        });
        
    }, []);
    const history = useHistory();
    const initialState = {
        email: '',
        password: ''
    };
    const [formState, setFormState] = useState({ ...initialState });
    const [formErrors, setFormErrors] = useState({
        email: '',
        password: ''
    });
    const { email, password } = formState;

    const [tncSate, setTncState] = useState(false);

    const onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });
        let errors = { ...formErrors }
        switch (name) {
            case 'email':
                errors.email = value.length < 1 ? 'This field is required!' : '';
                // errors.email = value.length < 1 ? 'This field is required!' : isValidEmail(value) ? '' : 'Email is not valid';
                break;
            case 'password':
                errors.password = value.length < 1 ? 'This field is required!' : '';
                // errors.password = value.length < 1 ? 'This field is required!' : passwordExpression(value) ? '' : 'Must contain at least one number, one Uppercase letter, one lowercase letter and one special character, and at least 8 characters';
                break;
            default:
                break;
        }
        setFormErrors({ ...formErrors, ...errors });
    }

    const isFormValid = () => {
        let valid = true;
        if (formState.email.length === 0 || formState.password.length === 0) {
            let errors = { ...formErrors }
            if (formState.email.length === 0) {
                errors.email = 'This field is required!';
            }

            if (formState.password.length === 0) {
                errors.password = 'This field is required!';
            }

            setFormErrors({ ...formErrors, ...errors });
            return false;
        }
        return valid;
    }

    const submitMethod = (event) => {
        event.preventDefault();
        if (isFormValid()) {
            let payload = {
                "username": formState.email,
                "password": formState.password,
                "domain": "smark-qa1.sitepm.com",
            };

            let formData = new FormData();
            formData.append("swcmTicket", apiCommonParams.swcmTicket);
            formData.append("jsonData", JSON.stringify(payload));

            trackPromise(
                loginUser(dispatch, formData).then((response) => {
                    if (response.userId) {
                       
                        toast.success('Login successfully!', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        history.push('/dashboard');
                    } else {
                        let error = response[0].errorMap;
                        let message = Object.values(error);
                        toast.error(message[0], {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }).catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            );
        }
    }

    const isAdminMethod = (userDetail) => {
        trackPromise(
            isAdminUser(dispatch, userDetail).then((response) => {
                if (response) { }
            }).catch((error) => {

            })
        );
    }

    const signupMethod = (event) => {
        event.preventDefault();
        history.push("/signup");
    }

    const forgotPwdMethod = (event) => {
        event.preventDefault();
        history.push("/forgot-password");
    }

    const forgotUsernameMethod = (event) => {
        event.preventDefault();
        history.push("/forgot-username");
    }
    

    return (
        <div className="login-back login-top">
            {!(keycloackValue && keycloackValue.authenticated) ? <div className="row">
               <div className="container">
                    <div className="col-lg-10 col-md-12 col-md-offset-1 row-eq-height">
                        <div className="col-md-5 col-sm-12 col-xs-12 login-inner login-screen ">
                            <h2>Hello...</h2>
                            <p className="text-left login-P-text">Sign in to your account to have access to more features</p>
                            <hr />
                            <h4>Don't have an account?</h4>
                            <span onClick={signupMethod} className="account-button">
                                <i className="fa fa-user" aria-hidden="true"></i> Sign Up
                            </span>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12 account-create login-screen">
                           <h2 className="mar-bot-30">Sign In</h2>
                            <div className="form-group mt-2 mar-bot-100">
                            <button type="submit" onClick={() => login()} disabled={loading} className="btn btn-ar btn-primary mar-bot-30">Login</button> 
                                </div>
                                <div className="col-md-6 nopad smark-user">
                                    <p className="text-left"><span onClick={forgotPwdMethod} className="forgot"> <i className="fa fa-lock" aria-hidden="true"></i> Forgot Password</span></p>
                                </div>
                                <div className="col-md-12">
                                    <img src={process.env.PUBLIC_URL + 'images/smark.png'} alt="Logo" width="150" className="img-responsive pull-right" />
                                </div>
                        </div>
                    </div>
                </div> 
                </div>:  <div className="spinner spinner-top">
         <Loader type="Oval" color="#191f39" height="70" width="70" />
       </div>}
           
        </div>
    );
}

export default SignIn;
